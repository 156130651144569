import React from "react";
import { Link, graphql } from "gatsby";

import ProductsContainer from "../components/containers/products-container";
import { Row, Col } from "react-bootstrap";

import { Seo } from "../components/helpers/seo";

const ProductsPage = ({ data }) => {
  return (
    <>
      <Seo title="Products" />
      <Row>
        <Col>
          <h1
            style={{
              paddingTop: "15px"
            }}
          >
            Products
          </h1>
          <hr />
          <ProductsContainer
            products={data.allShopifyProduct.nodes.sort(function(a, b) {
              return a.sort_number.value - b.sort_number.value;
            })}
            filters={{ category: "/products", querystring: "" }}
          />
        </Col>
      </Row>
      <Link to="/">Go back to the homepage</Link>
    </>
  );
};
export default ProductsPage;

export const query = graphql`
  {
    allShopifyProduct {
      nodes {
        title
        shopifyId
        description
        handle
        featuredImage {
          altText
          gatsbyImageData(width: 480)
        }
        metafields {
          type
          value
          key
        }
        sort_number: metafield(key: "sort_number", namespace: "my_fields") {
          value
        }
        seo {
          description
        }
      }
    }
  }
`;
